































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { RootRouter } from "@/utils/newRoutePathConstant";
import {
  MetaModule as metaStore,
  CommonStore as commonstore,
} from "@/store/modules";
import gpsProviderStore from "@/store/modules/gpsServiceProvider";
import { District, Palika } from "@/store/models/meta";
import { BSToAD } from "bikram-sambat-js";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";

@Component({
  components: {
    NepaliDatePicker,
  },
})
export default class component_name extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  active: number = 0;

  panCertificateFile: File[] = [];
  companyCertificateFile: File[] = [];

  $refs!: {
    file: InstanceType<typeof HTMLElement>;
  };

  get gpsProvider() {
    return gpsProviderStore.gpsProvider;
  }
  get province() {
    return metaStore.provinceList;
  }

  async created() {
    await metaStore.loadProvince();
    await metaStore.loadGender();
    await metaStore.loadDis();
    await metaStore.loadBloodGroup();
    const id = this.$route.query["id"];

    if (id) {
      this.isCreate = false;

      await gpsProviderStore.getServiceProviderById(id);
    } else {
      gpsProviderStore.resetField();
    }

    if (this.gpsProvider.profile.province) {
      this.districts = await metaStore.loadDistrict(
        this.gpsProvider.profile.province
      );
    }

    if (this.gpsProvider.profile.district) {
      this.municipality = await metaStore.loadMuncipality(
        this.gpsProvider.profile.district
      );
    }
  }
  get RootRouter() {
    return RootRouter;
  }

  bsDateChange(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      console.log(e, bsAD);
      this.gpsProvider.gpsServiceProvider.registrationDate = bsAD;
      this.gpsProvider.gpsServiceProvider.registrationDateBs = e;
    }
  }

  async onProvinceChange(e: any) {
    this.districts = await metaStore.loadDistrict(e);
  }

  async onDistrictChange(e: any) {
    this.municipality = await metaStore.loadMuncipality(e);
  }

  handleChangeCompanyCertificate(e: any) {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      this.companyCertificateFile = [e.target.files[0]];
      this.gpsProvider.gpsServiceProvider.companyCertificate =
        e.target.files[0];
    } else {
      this.$snotify.error("Please upload .jpeg or .jpg or .png file");
      e = "";
      this.companyCertificateFile = [];
      this.gpsProvider.gpsServiceProvider.companyCertificate = null;
    }
  }

  handleChangePanCertificate(e: any) {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      this.panCertificateFile = [e.target.files[0]];
      this.gpsProvider.gpsServiceProvider.panCertificate = e.target.files[0];
    } else {
      this.$snotify.error("Please upload .jpeg or .jpg or .png file");
      e = "";
      this.panCertificateFile = [];
      this.gpsProvider.gpsServiceProvider.panCertificate = null;
    }
  }

  async onSubmitClick() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    commonstore.showLoading();
    await gpsProviderStore.createGpsServiceProvider();
    this.$snotify.success("Form Submitted Successfully");
    commonstore.hideLoading();
    this.$router.push(RootRouter.RegistrationLandingPage);
  }
}
